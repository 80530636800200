<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-layout wrap pt-4>
        <v-flex xs12 xl6 pa-2 >
          <PublicItem v-bind:storage="userData" v-bind:isRequired="false" @stepper="winStepper" />
        </v-flex>
     <v-layout wrap justify-center pt-4>
      <v-flex>
        <v-tabs
          fixed-tabs
          v-model="tabValue"
          color="#000"
          centered
          style="overflow-x:auto"
          :slider-color="appColor"
        >
          <v-tab v-for="item in titleArray" :key="item.value">
            <span class="itemHeading"> {{ item.title }} </span>
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout></v-layout>
   
     <v-tabs-items v-model="tabValue">
      <template v-for="(item, index) in titleArray">
        <v-tab-item :key="item.value" :value="index">
          <v-layout wrap v-if="item.value == 'Active'">
            <v-flex xs12 v-if="activeCases.length > 0">
              <ReportDetails :storage="activeCases" />
            </v-flex>
            <v-flex v-else xs12 md12 align-self-center text-center pt-4>
              <span class="itemKey">No Data Found</span>
            </v-flex>
          </v-layout>
           <v-layout wrap v-if="item.value == 'Assigned'">
            <v-flex xs12 v-if="assignedCases.length > 0">
              <ReportDetails :storage="assignedCases" />
            </v-flex>
            <v-flex xs12 pt-4 md12 align-self-ce nter text-center v-else>
              <span class="itemKey">No Data Found</span>
            </v-flex>
          </v-layout>
           <v-layout wrap v-if="item.value == 'Captured'">
            <v-flex xs12 v-if="capturedCases.length > 0">
              <ReportDetails :storage="capturedCases" />
            </v-flex>
            <v-flex xs12 pt-4 md12 align-self-center text-center v-else>
              <span class="itemKey">No Data Found</span>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="item.value == 'Resolved'">
            <v-flex xs12 pt-4 v-if="resolvedCases.length > 0">
              <ReportDetails :storage="resolvedCases" />
            </v-flex>
            <v-flex xs12 md12 pt-4 align-self-center text-center v-else>
              <span class="itemKey">Case List is Empty</span>
            </v-flex>
          </v-layout>
         
        </v-tab-item>
        
      </template>
    </v-tabs-items>
   
  </div>
</template>
<script>
import axios from "axios";
import ReportDetails from "./reportDetails";
import PublicItem from "./publicItem";
export default {
  components: {
    PublicItem,
  ReportDetails },
  data() {
    return {
      appLoading: false,
      map: "",
      caseDetails: [],
      infoItem: null,
      caseMsg: null,
      contentString: null,
      ServerError:false,
      tabValue: 0,
      titleArray: [
        { title: "ACTIVE CASES", value: "Active" },
        { title: "ASSIGNED CASES", value: "Assigned" },
        { title: "CAPTURED CASES", value: "Captured" },
        { title: "RESOLVED CASES", value: "Resolved" },

        // { title: "COTTAGES", value: "cottage" },
      ],
      activeCases: [],
      assignedCases: [],
      resolvedCases: [],

      capturedCases: [],
      userData:[],
    };
  },

  mounted() {
    this.getData();
    this.getUser();
  },

  methods: {
   getData () {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/reports/all/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          userId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.caseDetails = response.data;
            this.activeCases = this.caseDetails.activeCases;
            this.assignedCases = this.caseDetails.assignedCases;
            this.capturedCases = this.caseDetails.capturedCases;
            this.resolvedCases = this.caseDetails.resolvedCases;
          }
          
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
      getUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/view/"+this.$route.query.id,
        headers: {
          token: localStorage.getItem("token"),
        },
       
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.caseDetails = response.data;
          
            this.userData = response.data.data
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
      winStepper(windowData) {
     if (windowData.type == "Suspend") {
        if (windowData.pageResponse.status) {
          this.msg = "User Suspended Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Suspended";
          this.showSnackBar = true;
        }
      }
        else if (windowData.type == "Activate") {
        if (windowData.pageResponse.status) {
          this.msg = "User Activated Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Activated";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>
<style  >
.posSticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}
</style>
